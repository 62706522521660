import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { GENIE_VISION_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import ContentSection from '@/components/Portfolio/ContentSection';
import NextProject from '@/components/Portfolio/NextProject';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import SoloPicture from '@/components/Portfolio/SoloPicture';
import DoublePictures from '@/components/Portfolio/DoublePictures';
import DownloadApp from '@/components/Portfolio/DownloadApp';

export default function APV({ data }) {
  const { t } = useTranslation();

  const tags = ['internal', 'ux', 'mobile', 'rn', 'rails'];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        websites={[t('website')]}
        description={t('description')}
        tags={tags}
      />

      <SoloPicture source={data.cover} alt={t('coverLmsg')} />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />
      <DoublePictures
        pictures={[
          {
            source: data.logo,
            alt: 'Notion',
          },
          {
            source: data.f1,
            alt: 'Multicanal scenarios',
          },
        ]}
      />
      <ContentSection title={t('paragraph2Title')} content={t('paragraph2')} />

      <DownloadApp
        iOSLink="https://apps.apple.com/fr/app/apv-au-premier-virage/id1566514118"
        androidLink="https://play.google.com/store/apps/details?id=com.apvapp"
      />

      <NextProject title={t('nextProject')} to={GENIE_VISION_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["apv", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    cover: file(relativePath: { eq: "projects/apv/app-screens.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    logo: file(relativePath: { eq: "projects/apv/apv-logo.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    f1: file(relativePath: { eq: "projects/apv/f1.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
